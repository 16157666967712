@import '../../assets/variables';

.footer {
  display: flex;
  background: #333;
  min-height: 100px;
  border-top: 1px solid $grey;
  p {
    display: flex;
    align-self: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 8px;
    color: #fff;
  }
}