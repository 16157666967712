@import '../../assets/variables';

.service {
  .section {
    padding: 0 0 30px 0;
  }
  ul.process {
    padding: 0;
    margin: 50px 0;
    list-style: none;
    display: flex;
    justify-content: center;
    flex-direction: column;
    li.step {
      padding: 15px 25px;
      background: $theme-color;
      color: #fff;
      border-radius: 4px;
      min-width: 180px;
      text-align: center;
      margin: auto;
    }
    li.link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 0;
      .bar {
        width: 5px;
        height: 45px;
        background: rgba($theme-color, 0.6);
      }
    }
  }
  ul.listColumn {
    padding: 0 0 0 20px;
    margin: 0;
  }
  .workingImage {
    width: 100%;
    height: auto;
    margin: 15px 0;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  }
}

@media screen and (min-width: 1042px) {
  .service {
    ul.process {
      flex-direction: row;
      li.link {
        .bar {
          width: 100%;
          height: 5px;
        }
      }
    }
  }
}