@import '../../../../assets/variables';

.summary {
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #fff;
    width: 90px;
    height: 90px;
    background: $theme-color;
    margin: 0 auto 50px auto;
    span {
      font-size: 4em;
    }
  }
  h2 {
    text-align: center;
  }
  .actions {
    margin-top: 40px;
    text-align: center;
  }
}