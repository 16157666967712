@import './assets/variables';
.app {
  .header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .content {
    padding-top: $header-height;
  }
}
