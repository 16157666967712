@import '../../assets/variables';

$banner-height: 350px;

.home {
  max-width: 2000px;
  margin: auto;
  .banner {
    position: relative;
    width: 100%;
    height: $banner-height;
    overflow: hidden;
    img {
      position: absolute;
      top: -50px;
      width: 990px;
      height: auto;
    }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      background: rgba($theme-color, 0.8);
      width: 100%;
      height: $banner-height;
      .callToActionWrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: $banner-height;
        .callToAction {
          color: #fff;
          width: 100%;
          padding: 0 30px;
          p {
            font-size: 1.5em;
          }
        }
      }
    }
  }
  .section {
    padding: 65px 0;
  }
}

@media screen and (min-width: 800px) {
  .home {
    .banner {
      .overlay {
        .callToActionWrapper {
          .callToAction {
            width: 50%;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 990px) {
  .home {
    .banner {
      img {
        width: 100%;
        height: auto;
      }
      .overlay {
        .callToActionWrapper {
          .callToAction {
            width: 40%;
          }
        }
      }
    }
  }
}