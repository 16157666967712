.contact {
  ul.contactInfo {
    list-style: none;
    padding: 0;
    margin: 30px auto;
    width: 285px;
    min-height: 180px;
    li {
      a {
        display: flex;
        align-items: center;
        height: 60px;
        color: #333;
            text-decoration: none;
        .icon {
          display: flex;
          padding: 10px 15px;
          color: #666;
          span {
            font-size: 2em;
          }
        }
        .label {
          font-size: 1.1em;
        }
        &:hover {
          .label {
            text-decoration: underline;
          }
        }
      }
    }
  }
}