* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f7f7f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Sticky Footer */
html, body, #root, .app {
  height: 100%;
}
.app {
  display: flex;
  flex-direction: column;
}
.content {
  flex: 1 0 auto;
}
.footer {
  flex-shrink: 0;
}

.page-container {
  max-width: 1200px;
  width: 100%;
  margin: auto;
}

.container {
  padding: 0 30px;
}

h1, h2, h3, h4, h5, h6 {
  color: #666666;
}

h1 {
  font-weight: 700;
}

h2, h3, h4, h5, h6 {
  font-weight: 500;
}

.page-title {
  font-weight: 500;
}

.section-title {
  font-weight: 500;
  color: #115CA0;
}

ul {
  padding-left: 20px;
}

ul li {
  font-weight: 300;
  line-height: 1.6em;
  padding: 5px 0;
}

p {
  font-weight: 300;
  line-height: 1.8em;
}

strong {
  font-weight: 500;
}

a {
  color: blue;
}

.mobileDrawerNav ul  {
  padding: 0;
  margin: 0;
  list-style: none;
}

.mobileDrawerNav ul li {
  display: block;
  width: 300px;
  padding: 0;
}

.mobileDrawerNav ul li a {
  display: block;
  padding: 15px 30px;
  color: #666;
  text-decoration: none;
  border-top: 1px solid #ddd;
}

.mobileDrawerNav ul li:nth-child(2) a {
  border-top: none;
}

.mobileDrawerNav ul li a:hover {
  text-decoration: none;
  color: #115CA0;
  background: #eee;
}

.mobileDrawerNav .closeBtn {
  display: flex;
  justify-content: flex-end;
}

.mobileDrawerNav .closeBtn button {
  margin: 5px;
}

@media screen and (min-width: 500px) {
  .page-container {
    padding: 0 30px;
  }
}
.App_app__3FM3v .App_header__3qMlP {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1; }

.App_app__3FM3v .App_content__Yy_Ry {
  padding-top: 85px; }

.Header_header__3Vxd9 {
  position: relative;
  background: #fff;
  padding: 0 10px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 85px;
  border-bottom: 1px solid #ccc;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }
  .Header_header__3Vxd9 a {
    display: flex;
    text-decoration: none;
    color: #666;
    height: 85px;
    outline: 0; }
    .Header_header__3Vxd9 a:hover {
      text-decoration: none; }
    .Header_header__3Vxd9 a .Header_brand__2ahh2 {
      display: flex;
      align-items: center; }
      .Header_header__3Vxd9 a .Header_brand__2ahh2 .Header_logo__1mhl5 {
        margin-right: 10px; }
      .Header_header__3Vxd9 a .Header_brand__2ahh2 .Header_title__1dkKy h1 {
        padding: 0;
        margin: 0;
        font-size: 1.5em; }
      .Header_header__3Vxd9 a .Header_brand__2ahh2 .Header_title__1dkKy p.Header_subheading__Ujn-y {
        font-size: 0.9em;
        margin: 0;
        line-height: 1em; }
  .Header_header__3Vxd9 .Header_desktopNav__3vaf8 {
    display: none;
    justify-content: flex-end;
    flex-grow: 1; }
    .Header_header__3Vxd9 .Header_desktopNav__3vaf8 nav ul {
      padding-left: 0;
      margin: 0; }
      .Header_header__3Vxd9 .Header_desktopNav__3vaf8 nav ul li {
        display: inline-flex;
        align-items: center;
        height: 85px;
        padding: 0; }
        .Header_header__3Vxd9 .Header_desktopNav__3vaf8 nav ul li a {
          line-height: 85px;
          padding: 0 20px; }
          .Header_header__3Vxd9 .Header_desktopNav__3vaf8 nav ul li a.active {
            border-bottom: 4px solid #2e90e9; }
  .Header_header__3Vxd9 .Header_mobileNav__3E0Kx {
    display: flex;
    position: absolute;
    right: 40px; }
    .Header_header__3Vxd9 .Header_mobileNav__3E0Kx .Header_menuButton__2OxnT button span {
      font-size: 1.2em; }

@media screen and (min-width: 768px) {
  .Header_header__3Vxd9 .Header_desktopNav__3vaf8 {
    display: flex; }
  .Header_header__3Vxd9 .Header_mobileNav__3E0Kx {
    display: none; } }

.Footer_footer__2YGzB {
  display: flex;
  background: #333;
  min-height: 100px;
  border-top: 1px solid #ccc; }
  .Footer_footer__2YGzB p {
    display: flex;
    align-self: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 8px;
    color: #fff; }

.Home_home__TpXyP {
  max-width: 2000px;
  margin: auto; }
  .Home_home__TpXyP .Home_banner__3sHjT {
    position: relative;
    width: 100%;
    height: 350px;
    overflow: hidden; }
    .Home_home__TpXyP .Home_banner__3sHjT img {
      position: absolute;
      top: -50px;
      width: 990px;
      height: auto; }
    .Home_home__TpXyP .Home_banner__3sHjT .Home_overlay__3Z1fK {
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(17, 92, 160, 0.8);
      width: 100%;
      height: 350px; }
      .Home_home__TpXyP .Home_banner__3sHjT .Home_overlay__3Z1fK .Home_callToActionWrapper__1hm0g {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 350px; }
        .Home_home__TpXyP .Home_banner__3sHjT .Home_overlay__3Z1fK .Home_callToActionWrapper__1hm0g .Home_callToAction__MrL4J {
          color: #fff;
          width: 100%;
          padding: 0 30px; }
          .Home_home__TpXyP .Home_banner__3sHjT .Home_overlay__3Z1fK .Home_callToActionWrapper__1hm0g .Home_callToAction__MrL4J p {
            font-size: 1.5em; }
  .Home_home__TpXyP .Home_section__23zJF {
    padding: 65px 0; }

@media screen and (min-width: 800px) {
  .Home_home__TpXyP .Home_banner__3sHjT .Home_overlay__3Z1fK .Home_callToActionWrapper__1hm0g .Home_callToAction__MrL4J {
    width: 50%; } }

@media screen and (min-width: 990px) {
  .Home_home__TpXyP .Home_banner__3sHjT img {
    width: 100%;
    height: auto; }
  .Home_home__TpXyP .Home_banner__3sHjT .Home_overlay__3Z1fK .Home_callToActionWrapper__1hm0g .Home_callToAction__MrL4J {
    width: 40%; } }

.About_about__1NqIC .About_description__1f6j4 {
  font-weight: 300; }
  .About_about__1NqIC .About_description__1f6j4 p {
    line-height: 1.8em;
    margin-top: 0;
    margin-bottom: 30px; }
  .About_about__1NqIC .About_description__1f6j4 .About_providedServices__Y8kB7 p {
    line-height: 1em;
    margin-bottom: 10px; }

.ProfileQuote_profile__3DmR0 {
  max-width: 380px;
  margin: 0 auto 40px auto;
  padding: 25px 0 20px 0; }
  .ProfileQuote_profile__3DmR0 img {
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 30px;
    border: 1px solid #ddd;
    border-radius: 4px; }
  .ProfileQuote_profile__3DmR0 blockquote {
    border-left: 4px solid #ddd;
    padding-left: 20px; }

.Summary_summary__epELe .Summary_icon__G8oMz {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
  width: 90px;
  height: 90px;
  background: #115CA0;
  margin: 0 auto 50px auto; }
  .Summary_summary__epELe .Summary_icon__G8oMz span {
    font-size: 4em; }

.Summary_summary__epELe h2 {
  text-align: center; }

.Summary_summary__epELe .Summary_actions__1Es5d {
  margin-top: 40px;
  text-align: center; }

.Companies_companies__3p_bC h2 {
  text-align: center;
  margin-bottom: 60px; }

.Companies_companies__3p_bC ul {
  list-style: none;
  padding: 0;
  margin: 0 0 60px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; }
  .Companies_companies__3p_bC ul li a {
    display: block;
    margin: 20px 20px; }
    .Companies_companies__3p_bC ul li a:hover {
      opacity: 0.5; }

.Companies_companies__3p_bC .Companies_seeMore__ylcgo {
  text-align: center; }

.Service_service__1fmlX .Service_section__2Nfdp {
  padding: 0 0 30px 0; }

.Service_service__1fmlX ul.Service_process__3K8B0 {
  padding: 0;
  margin: 50px 0;
  list-style: none;
  display: flex;
  justify-content: center;
  flex-direction: column; }
  .Service_service__1fmlX ul.Service_process__3K8B0 li.Service_step__ZcBYd {
    padding: 15px 25px;
    background: #115CA0;
    color: #fff;
    border-radius: 4px;
    min-width: 180px;
    text-align: center;
    margin: auto; }
  .Service_service__1fmlX ul.Service_process__3K8B0 li.Service_link__3Wx3D {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0; }
    .Service_service__1fmlX ul.Service_process__3K8B0 li.Service_link__3Wx3D .Service_bar__2vsx7 {
      width: 5px;
      height: 45px;
      background: rgba(17, 92, 160, 0.6); }

.Service_service__1fmlX ul.Service_listColumn__19bZH {
  padding: 0 0 0 20px;
  margin: 0; }

.Service_service__1fmlX .Service_workingImage__3Zc0y {
  width: 100%;
  height: auto;
  margin: 15px 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); }

@media screen and (min-width: 1042px) {
  .Service_service__1fmlX ul.Service_process__3K8B0 {
    flex-direction: row; }
    .Service_service__1fmlX ul.Service_process__3K8B0 li.Service_link__3Wx3D .Service_bar__2vsx7 {
      width: 100%;
      height: 5px; } }

.withPageTemplate_page__2LoNc .withPageTemplate_banner__3ZY1d {
  position: relative;
  width: 100%;
  height: 250px;
  overflow: hidden;
  z-index: -1; }
  .withPageTemplate_page__2LoNc .withPageTemplate_banner__3ZY1d img {
    position: absolute;
    top: 0;
    left: 0; }
  .withPageTemplate_page__2LoNc .withPageTemplate_banner__3ZY1d .withPageTemplate_overlay__K_ywp {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(17, 92, 160, 0.8);
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .withPageTemplate_page__2LoNc .withPageTemplate_banner__3ZY1d .withPageTemplate_overlay__K_ywp h2 {
      color: #fff;
      font-size: 2.5em;
      text-align: center;
      margin-top: -65px;
      margin-bottom: 0; }

.withPageTemplate_page__2LoNc .withPageTemplate_content__2wa9V {
  margin-top: -65px;
  padding-bottom: 0; }

@media screen and (min-width: 500px) {
  .withPageTemplate_page__2LoNc .withPageTemplate_content__2wa9V {
    padding-bottom: 50px; } }

.History_history__2vwoO ul.History_companies__wl2nR {
  padding: 0;
  margin: 0;
  list-style: none; }
  .History_history__2vwoO ul.History_companies__wl2nR li {
    margin-bottom: 4px; }
    .History_history__2vwoO ul.History_companies__wl2nR li a {
      outline: 0;
      display: flex;
      align-items: center;
      width: 100%;
      height: 60px;
      text-decoration: none;
      color: #333; }
      .History_history__2vwoO ul.History_companies__wl2nR li a .History_label__3qFU3 {
        display: flex;
        align-items: center;
        padding: 0 20px;
        width: 100%;
        height: 60px;
        line-height: 1em; }
      .History_history__2vwoO ul.History_companies__wl2nR li a .History_badge__32la3 {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #528dd1;
        opacity: 0.7;
        height: 60px;
        width: 60px;
        font-weight: 500;
        color: #fff; }
      .History_history__2vwoO ul.History_companies__wl2nR li a:hover .History_label__3qFU3 {
        background: #eee; }

.Contact_contact__3hySO ul.Contact_contactInfo__1qco3 {
  list-style: none;
  padding: 0;
  margin: 30px auto;
  width: 285px;
  min-height: 180px; }
  .Contact_contact__3hySO ul.Contact_contactInfo__1qco3 li a {
    display: flex;
    align-items: center;
    height: 60px;
    color: #333;
    text-decoration: none; }
    .Contact_contact__3hySO ul.Contact_contactInfo__1qco3 li a .Contact_icon__2CiPd {
      display: flex;
      padding: 10px 15px;
      color: #666; }
      .Contact_contact__3hySO ul.Contact_contactInfo__1qco3 li a .Contact_icon__2CiPd span {
        font-size: 2em; }
    .Contact_contact__3hySO ul.Contact_contactInfo__1qco3 li a .Contact_label__2I1fM {
      font-size: 1.1em; }
    .Contact_contact__3hySO ul.Contact_contactInfo__1qco3 li a:hover .Contact_label__2I1fM {
      text-decoration: underline; }

