@import '../../assets/variables';

$li-height: 60px;
.history {
  ul.companies {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      margin-bottom: 4px;
      a {
        outline: 0;
        display: flex;
        align-items: center;
        width: 100%;
        height: $li-height;
        text-decoration: none;
        color: #333;
        .label {
          display: flex;
          align-items: center;
          padding: 0 20px;
          width: 100%;
          height: $li-height;
          line-height: 1em;
        }
        .badge {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #528dd1;
          opacity: 0.7;
          height: $li-height;
          width: $li-height;
          font-weight: 500;
          color: #fff;
        }
        &:hover {
          .label {
            background: #eee;
          }
        }
      }
    }
  }
}