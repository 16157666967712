* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f7f7f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Sticky Footer */
html, body, #root, .app {
  height: 100%;
}
.app {
  display: flex;
  flex-direction: column;
}
.content {
  flex: 1 0 auto;
}
.footer {
  flex-shrink: 0;
}

.page-container {
  max-width: 1200px;
  width: 100%;
  margin: auto;
}

.container {
  padding: 0 30px;
}

h1, h2, h3, h4, h5, h6 {
  color: #666666;
}

h1 {
  font-weight: 700;
}

h2, h3, h4, h5, h6 {
  font-weight: 500;
}

.page-title {
  font-weight: 500;
}

.section-title {
  font-weight: 500;
  color: #115CA0;
}

ul {
  padding-left: 20px;
}

ul li {
  font-weight: 300;
  line-height: 1.6em;
  padding: 5px 0;
}

p {
  font-weight: 300;
  line-height: 1.8em;
}

strong {
  font-weight: 500;
}

a {
  color: blue;
}

.mobileDrawerNav ul  {
  padding: 0;
  margin: 0;
  list-style: none;
}

.mobileDrawerNav ul li {
  display: block;
  width: 300px;
  padding: 0;
}

.mobileDrawerNav ul li a {
  display: block;
  padding: 15px 30px;
  color: #666;
  text-decoration: none;
  border-top: 1px solid #ddd;
}

.mobileDrawerNav ul li:nth-child(2) a {
  border-top: none;
}

.mobileDrawerNav ul li a:hover {
  text-decoration: none;
  color: #115CA0;
  background: #eee;
}

.mobileDrawerNav .closeBtn {
  display: flex;
  justify-content: flex-end;
}

.mobileDrawerNav .closeBtn button {
  margin: 5px;
}

@media screen and (min-width: 500px) {
  .page-container {
    padding: 0 30px;
  }
}