.profile {
  max-width: 380px;
  margin: 0 auto 40px auto;
  padding: 25px 0 20px 0;
  img {
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 30px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  blockquote {
    border-left: 4px solid #ddd;
    padding-left: 20px;
  }
}