.companies {
  h2 {
    text-align: center;
    margin-bottom: 60px;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0 0 60px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    li {
      a {
        display: block;
        margin: 20px 20px;
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
  .seeMore {
    text-align: center;
  }
}