@import '../../assets/variables';
$card-offset: -65px;
$banner-height: 250px;
.page {
  .banner {
    position: relative;
    width: 100%;
    height: $banner-height;
    overflow: hidden;
    z-index: -1;
    img {
      position: absolute;
      top: 0;
      left: 0;
    }
    .overlay {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba($theme-color, 0.8);
      height: $banner-height;
      display: flex;
      justify-content: center;
      align-items: center;
      h2 {
        color: #fff;
        font-size: 2.5em;
        text-align: center;
        margin-top: $card-offset;
        margin-bottom: 0;
      }
    }
  }
  .content {
    margin-top: $card-offset;
    padding-bottom: 0;
  }
}

@media screen and (min-width: 500px) {
  .page {
    .content {
      padding-bottom: 50px;
    }
  }
}