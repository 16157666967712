.about {
  .description {
    font-weight: 300;
    p {
      line-height: 1.8em;
      margin-top: 0;
      margin-bottom: 30px;
    }
    .providedServices {
      p {
        line-height: 1em;
        margin-bottom: 10px;
      }
    }
  }
}